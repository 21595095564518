import './bootstrap'

import Alpine from 'alpinejs'
import intersect from '@alpinejs/intersect'
import focus from '@alpinejs/focus'
import mask from '@alpinejs/mask'

Alpine.plugin(focus)
Alpine.plugin(intersect)
Alpine.plugin(mask)

document.addEventListener('alpine:init', () => {
    Alpine.data('header', () => ({
        lastScrollTop: 0,
        delta: 5,
        height: 65,
        init() {
            this.height = this.$el.getBoundingClientRect().height
        },
        scroll: {
            ['@scroll.window.debounce']() {
                let scrollTop = window.scrollY
                this.height = this.$el.getBoundingClientRect().height

                if (Math.abs(this.lastScrollTop - scrollTop) <= this.delta) return

                if (scrollTop > this.lastScrollTop && scrollTop > this.height) {
                    this.$el.style.top = '-' + this.$el.offsetHeight + 'px'
                } else {
                    this.$el.style.top = 0
                }

                this.lastScrollTop = scrollTop
            }
        }
    }))

    Alpine.data('dropdown', () => ({
        open: false,
        toggle() {
            if (this.open) {
                return this.close()
            }

            this.$refs.button.focus()

            this.open = true
        },
        close(focusAfter) {
            if (!this.open) return

            this.open = false

            focusAfter && focusAfter.focus()
        },
        openDrop() {
            if (this.open) return
            this.open = true
        },
        clickDrop() {
            if (!this.open) this.open = true
            else {
                this.$refs.link && this.$refs.link.click()
            }
        }
    }))
})
window.Alpine = Alpine
Alpine.start()
Array.from(document.querySelectorAll('[x-cloak]')).forEach((e) => e.removeAttribute('x-cloak'))
